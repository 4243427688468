window.addEventListener("load", function(event){
       
       const contenSlider = document.querySelectorAll('.slidercontent-block');
       //console.log(contenSlider);
       if(contenSlider != null){
            jQuery(contenSlider).slick({
                prevArrow: jQuery('.prev'),
                nextArrow: jQuery('.next')
           });  
       };
   
   
});

