import { __ } from '@wordpress/i18n';
import { InnerBlocks } from '@wordpress/block-editor';

export default function Save(props){

    const { attributes} = props;
    const { row_start, column_start, row_end, column_end } = attributes;
   
    return(
        <div className='item-single-grid' style={{
            gridRowStart:row_start,
            gridColumnStart:column_start,
            gridRowEnd:row_end,
            gridColumnEnd:column_end
        }}>
            <InnerBlocks.Content />
        </div>

    );

    
}
