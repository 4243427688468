import {registerBlockType} from '@wordpress/blocks';
import {__} from '@wordpress/i18n';
import Edit from "./edit";
import Save from "./save";

registerBlockType("plm/single-grid",{
    title: __("elemento"),
    description: __('Single grid content'),
    icon: "excerpt-view",
    parent:['plm/grid'],
    attributes:{
        blockId:{
            type:'string'
        },
        column_start:{
            type:'string',
            default:'auto'
        },
        column_end:{
            type:'string',
            default:'auto'
        },
        row_start:{
            type:'string',
            default:'auto'
        },
        row_end:{
            type:'string',
            default:'auto'
        }
    },
    edit: Edit,
    save: Save
})