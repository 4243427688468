import { __ } from '@wordpress/i18n';
import { useBlockProps, InnerBlocks, InspectorControls, BlockAlignmentToolbar } from '@wordpress/block-editor';
import{Panel, PanelBody, TextControl} from "@wordpress/components"
import { useRef, useEffect} from "@wordpress/element";

export default function Edit(props){

    const { attributes, setAttributes, clientId} = props;
    const { blockId, grid_columns, grid_gap} = attributes;

    if ( ! blockId ) {
        setAttributes( { blockId: clientId } );
    }
    
    const changeGrid = () =>{
        const contentGrids = document.querySelector(`.class-id-${clientId}>.block-editor-inner-blocks>.block-editor-block-list__layout`);
        contentGrids.style.gridTemplateColumns = grid_columns;
        contentGrids.style.gap = grid_gap;
    }
  
    useEffect(() => {
        changeGrid();
    }, []);

    return(
        <>    
            <InspectorControls>
                <Panel>
                    <PanelBody title={__('Opciones de del grid')} className="grid-options">
                        <TextControl 
                            help="define la distribución de las columnsa con las opciones css grid"
                            className='grid-options__values'
                            label={__("Columnas")}
                            value= {grid_columns}
                            onChange={(newgrid_columns)=> setAttributes({
                                ...changeGrid(),
                                grid_columns:newgrid_columns,
                            })}
                        />
                        <TextControl
                            className='grid-gap-options'
                            label={__("Espaciado")}
                            value={grid_gap}
                            onChange={(newgrid_gap)=>setAttributes({
                                ...changeGrid(),
                                grid_gap:newgrid_gap
                            })}
                        />

                    </PanelBody>
                </Panel>
            </InspectorControls>
            <div
                { ...useBlockProps({
                    className:`class-id-${clientId} content-grid ${attributes.align}`
                })}
            >
                <InnerBlocks allowedBlocks={ ['plm/single-grid']} 
                />
            </div>
            
        </>
    )
}

