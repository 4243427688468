import { __ } from '@wordpress/i18n';
import { InnerBlocks } from '@wordpress/block-editor';
import "./index.css";

export default function save(props){
    const { attributes} = props;
    const { grid_columns, grid_gap} = attributes;
   
    return(
        <div className='grid-content-block' style={{gridTemplateColumns:grid_columns, gap:grid_gap}}>
            <InnerBlocks.Content />
        </div>

    );

    
}

