import { __ } from '@wordpress/i18n';
import { useBlockProps, InnerBlocks, InspectorControls } from '@wordpress/block-editor';
import{Panel, PanelBody, TextControl, Flex, FlexBlock, FlexItem} from "@wordpress/components"
import { useRef, useEffect} from "@wordpress/element";

export default function Edit(props){

    const { attributes, setAttributes, clientId} = props;
    const { blockId, row_start, column_start, row_end, column_end } = attributes;

    if ( ! blockId ) {
        setAttributes( { blockId: clientId } );
    }

    const changeGrid = () =>{
        const singleGrid = document.querySelector(`#block-${clientId}`);
        singleGrid.style.gridRowStart = row_start;
        singleGrid.style.gridColumnStart = column_start;
        singleGrid.style.gridRowEnd = row_end;
        singleGrid.style.gridColumnEnd = column_end;
    }

    
    useEffect(() => {
        changeGrid();
    }, []);

    return(
        <>
            <InspectorControls>
                <Panel>
                    <PanelBody title={__('Opciones contenido')} className="content-options">
                        <Flex>
                            <FlexBlock>
                                <TextControl 
                                    className='start-column'
                                    label={__("columna inicial")}
                                    value= {column_start}
                                    onChange={(newColumn_start)=> setAttributes({
                                        ...changeGrid(),
                                        column_start:newColumn_start,
                                    })}
                                />
                            </FlexBlock>

                            <FlexBlock>
                                <TextControl
                                    className='end-column'
                                    label={__("Columna final")}
                                    value={column_end}
                                    onChange={(newColumn_end)=>setAttributes({
                                        ...changeGrid(),
                                        column_end:newColumn_end
                                    })}
                                />
                            </FlexBlock>
                        </Flex>
                        <Flex>
                            <FlexBlock>
                                <TextControl 
                                    className='start-row'
                                    label={__("fila inicial")}
                                    value= {row_start}
                                    onChange={(newRow_start)=> setAttributes({
                                        ...changeGrid(),
                                        row_start:newRow_start,
                                    })}
                                />
                            </FlexBlock>

                            <FlexBlock>
                                <TextControl
                                    className='end-row'
                                    label={__("fila final")}
                                    value={row_end}
                                    onChange={(newRow_end)=>setAttributes({
                                        ...changeGrid(),
                                        row_end:newRow_end
                                    })}
                                />
                            </FlexBlock>
                        </Flex>
                        
                    </PanelBody>
                </Panel>
            </InspectorControls>

            <div { ...useBlockProps({
                className:`${clientId} single-grid`
            })}>
                <InnerBlocks/>
            </div>
        </>
    )
}
