import {registerBlockType} from "@wordpress/blocks";

import meta from "../block.json";
import Edit from "./edit";
import save from "./save";

import './single-grid/index';
import "./style.css";
import "./index.css";
import "./script";

registerBlockType(meta, {
  edit: Edit,
  save: save
});

